<template>
  <b-container fluid="xl">
    <div v-show="items.length">
      <b-row cols="1" cols-sm="1" cols-md="2" cols-lg="3">
        <b-col v-for="(item, index) in items" :key="index">
      <b-card class="xnshadow mb-4" :body-class="item.connected?'':'offlin'" header-tag="header" :header-class="item.stp?'text-warning':'text-white'" :header-bg-variant="item.connected?'success':'danger'">
        <template v-slot:header>
          <p class="mb-0">{{sitename}}<span class="ml-3 mr-4" v-b-tooltip.hover :title="item.mac">#{{item.chargerid}}</span>
          <img :src="item.sigid" style="width:20px;height:20px;" :alt="item.sigmsg" :title="item.sigmsg"/>
          <b-button style="position:absolute;right:16px" size="sm" variant="outline-primary" :disabled="item.connected==0" @click="docmd(1,item)">重启</b-button>
          </p>
        </template>
        <b-card-text>
          <b-row cols="2" class="mb-2">
            <div><span class="btn-sm">{{item.connected?'数据时间':'离线时间'}}</span><span style="font-size:small;" @click="gotobeeptest(item)">{{item.connected?item.beep:item.ofline}}</span></div>
            <div><span class="btn-sm">用电抄表</span><span style="font-size:small;">{{item.pow}}</span></div>
            <div><span class="btn-sm" :class="item.ac?'text-danger':''">{{acs[item.ac]}}</span><span style="font-size:small;">{{localtime}}</span></div>
            <div><span class="btn-sm"><span @click="docmd(18,item)">设备温度</span></span><span style="font-size:small;">{{item.tp[0]}}/{{item.tp[1]}}/{{item.tp[2]}}</span></div>
            <div class="text-secondary"><span class="btn-sm">电压电流</span><span style="font-size:small;"><span @click="changepvnv(index)">{{item.pvv}}</span>/{{item.pii}}</span></div>
            <div class="text-secondary"><span class="btn-sm">急停开箱</span><span style="font-size:small;">{{item.stp?'按下':'正常'}}/{{item.ta?'已开':'已关'}}</span></div>
          </b-row>
          <b-row cols="2" style="font-size:small;">
            <div class="pt-2 pb-1">
              <b-col style="padding-left:0px;padding-right:0px;" class="mb-2">
                <span class="btn-sm">{{item.imax[0]?'左枪电量':'左枪禁用'}}</span>
                <span>{{item.pw[0]}}</span>
              </b-col>
              <b-col style="padding-left:0px;padding-right:0px;" class="mb-2" :class="item.imax[0]?'':'invalid'">
                  <span class="btn-sm">通断用户</span>
                  <b-link href @click="togglesw(0,item)" :class="sts[item.sw[0]]" class="text-decoration-none mr-2">{{sws[item.sw[0]]}}</b-link>
                <span><b-link href @click="showusr(0,item)" class="text-decoration-none mr-1" :class="item.openid[0]?'bg-primary text-white':''" :disabled="item.openid[0]==''">{{item.openid[0]?'有人':'无人'}}</b-link></span>
              </b-col>
              <b-col style="padding-left:0px;padding-right:0px;" class="mb-2" :class="item.imax[0]?'':'invalid'">
              <span class="btn-sm">电流状态</span><span>
              <span>{{item.pi[0]}}</span>
              <span class="ml-1 greyco">{{item.st[0]}}&nbsp;&nbsp;</span></span>
              <span>{{item.imax[0]}}</span>
              </b-col>
              <b-col style="padding-left:0px;padding-right:0px;" class="btn-sm" :class="item.imax[0]?'':'invalid'"><span class="btn-sm">正负CP</span><span class="ml-2">{{item.cp[0]}}<span class="ml-1 greyco">{{item.cz[0]}}</span></span></b-col>
            </div>
            <div class="pt-2 pb-1">
              <b-col style="padding-left:0px;padding-right:0px;" class="mb-2">
                <span class="btn-sm">{{item.imax[1]?'右枪电量':'右枪禁用'}}</span>
                <span>{{item.pw[1]}}</span>
              </b-col>
              <b-col style="padding-left:0px;padding-right:0px;" class="mb-2" :class="item.imax[1]?'':'invalid'">
                <span class="btn-sm">通断用户</span>
                <b-link href @click="togglesw(1,item)" :class="sts[item.sw[1]]" class="text-decoration-none mr-2">{{sws[item.sw[1]]}}</b-link>
                <span><b-link href @click="showusr(1,item)" class="text-decoration-none mr-1" :class="item.openid[1]?'bg-primary text-white':''" :disabled="item.openid[1]==''">{{item.openid[1]?'有人':'无人'}}</b-link></span>
              </b-col>
              <b-col style="padding-left:0px;padding-right:0px;" class="mb-2" :class="item.imax[1]?'':'invalid'">
              <span class="btn-sm">电流状态</span><span>
              <span>{{item.pi[1]}}</span>
              <span class="ml-1 greyco">{{item.st[1]}}&nbsp;&nbsp;</span></span>
              <span>{{item.imax[1]}}</span>
              </b-col>
              <b-col style="padding-left:0px;padding-right:0px;" class="btn-sm" :class="item.imax[1]?'':'invalid'"><span class="btn-sm">正负CP</span><span class="ml-2">{{item.cp[1]}}</span><span class="ml-1 greyco">{{item.cz[1]}}</span></b-col>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-show="maskenabled" class="fullmask" @click="closemask">
      <div class="navbar-fixed-top alert-danger text-left pt-2 pb-2" :show="alertmsg" @click="closemask">{{alertmsg}}</div>
      <div class="imgshow xnshadow">
        <img :src="bigheadimgurl" style="max-width:100vw;max-height:50vh"/>
      </div>
    </div>
  </b-container>
</template>

<style>
  a{color:rgba(0,102,0,0.5);}
  .offlin {background-color:#D3D3D3;filter:Alpha(Opacity=60);opacity:0.6;color:#666}
</style>
<script>
  let reloades = 0;
  export default {
    name: 'monitr1',
    created() {
      this.gettime();
    },
    mounted() {
      this.fetchData();
    },
    data() {
      return {
        maskenabled: false,
        bigheadimgurl: '',
        sitename: '(无名电站)',
        acs: ['本地时间','接地不良','火零反接','接地检测'],
        sws: ['已断','已通','禁用'],
        sts: ['text-info', 'text-danger', 'text-secondary'],
        localtime:'00:00:00',
        items: []
      };
    },
    methods: {
      gettime(){
        let nowtm = new Date();
        let hour = nowtm.getHours();
        let minu = nowtm.getMinutes();
        let secd = nowtm.getSeconds();
        if (hour<10) hour = '0'+hour;
        if (minu<10) minu = '0'+minu;
        if (secd<10) secd = '0'+secd;
        this.localtime = hour+':'+minu+':'+secd;
        if (this.$route.fullPath.indexOf('monitr1')>0) setTimeout(this.gettime, 900);
      },
      changepvnv(idx) {
        this.items[idx].pvv = this.items[idx].pnv;
      },
      closemask() {
        this.maskenabled = false;
        this.alertmsg = '';
      },
      showusr(id,itm) {
        reloades = 0;
        let cid = itm.chargerid;
        let pow = Number(itm.pw[id])*1000;
        let oid = itm.openid[id];
        let avgpow = (itm.pi[id]*itm.pvv/1000).toFixed(1);
        let theapi = '/showsecuser?tm='+new Date().getTime()+'&app='+this.$route.params.app+'&oid='+oid+'&cid='+cid+'&id='+this.$route.params.id+'&sec='+this.$route.params.sec;
        this.axios.get(theapi).then(axresp => {
          this.bigheadimgurl = axresp.data.url;
          let powuse = pow - axresp.data.powstart;
          if (powuse<0) powuse = 0;
          powuse = powuse + axresp.data.powused;
          powuse = (powuse/1000).toFixed(3);
          this.alertmsg = axresp.data.nickname+'['+axresp.data.mobile+']'+axresp.data.msg+', 当前功率'+avgpow+'千瓦, 已用电'+powuse+'度';
          this.maskenabled = true;
        });
      },
      togglesw(id,itm) {
        reloades = 0;
        let theapi;
        if (itm.connected) {
          let val = itm.sw[id]*100+id;
          theapi = '/cmdsecure?tm='+new Date().getTime()+'&app='+this.$route.params.app+'&mac='+itm.mac+'&cmd=14&val='+val+'&id='+this.$route.params.id+'&sec='+this.$route.params.sec
          if (itm.cp[id]>10 && itm.sw[id]==0) {
            theapi = theapi + '&openid=' + itm.openid[id];
          }
        }
        this.axios.get(theapi).then();        
      },
      docmd(id,itm) {
        reloades = 0;
        if (id==1) itm.connected=0;
        let theapi = '/cmdsecure?tm='+new Date().getTime()+'&app='+this.$route.params.app+'&mac='+itm.mac+'&cmd='+id+'&id='+this.$route.params.id+'&sec='+this.$route.params.sec;
        this.axios.get(theapi).then();
      },
      fetchData() {
        let theapi = '/monitors1?tm='+new Date().getTime()+'&r='+reloades;
        theapi = theapi + '&app='+this.$route.params.app;
        theapi = theapi + '&id='+this.$route.params.id;
        theapi = theapi + '&sec='+this.$route.params.sec;
        this.axios.get(theapi).then((axresp) => {
          if (axresp.status==200) {
            this.items = axresp.data.Items;
            this.sitename = axresp.data.sitename;
          } else {
            this.items = [];
          }
          if (this.$route.fullPath.indexOf('monitr1')>0 && reloades<10000) {
            let reloadms = 1000;
            if (this.items.length<4) {
              reloadms = 500;
            } else {
              if (reloades<100) {
                reloadms = 800;
              } else if (reloades<500) {
                reloadms = 1000;
              } else if (reloades<1000) {
                reloadms = 2000;
              } else {
                reloadms = 3000;
              }
            }
            setTimeout(this.fetchData, reloadms);
            reloades++;
          }
        });
      },
    }
  }
</script>
